import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './GlobalStyles';
//components
import { YellowButton } from './layout/StyledComponents';
//images
import arrow from '../images/icons/black-arrow-right.svg';
import yellowArrow from '../images/icons/yellow-arrow-right.svg';
import { StaticImage } from 'gatsby-plugin-image';
import phoneIcon from '../images/icons/mobile-black.svg';

const Panel = styled.section`
    /* bottom: 2rem; */

    background: ${colors.green};
    padding-top: 128px;
    .main-image {
        border-radius: 10px;
        margin-bottom: 32px;
    }
    .inner-container {
        margin: 0 32px;
    }
    h2 {
        font-size: 32px;
        color: ${colors.white};
    }
    h3 {
        color: ${colors.yellow};
    }
    .button-group {
        margin-top: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    button {
        cursor: pointer;
        justify-content: center;
        padding: 32px;
        box-sizing: border-box;
        width: 100%;
        font-size: 18px;
        display: flex;
        align-items: center;
        img {
            position: relative;
            right: 8px;
        }
    }
    @media ${device.tablet} and (max-width: 1023px) {
        h2 {
            margin-bottom: 32px;
            font-size: 2.3rem;
            line-height: 52px;
        }
        .button-group {
            display: flex;
            gap: 32px;
            justify-content: space-between;
        }
    }
    @media ${device.laptop} and (max-width: 1279px) {
        h2 {
            font-size: 2.2em;
            line-height: 43px;
        }
    }
    @media ${device.laptop} {
        .inner-container {
            display: flex;
            flex-direction: column;
            max-width: 900px;
            margin: 0 auto;
        }
        h2 {
            text-align: center;
        }
        h3 {
            text-align: center;
            font-size: 20px;
        }
        .main-image {
            align-self: center;
            border-radius: 10px;
            width: 600px;
            height: 500px;
            margin: 0 auto;
            margin-bottom: 32px;
        }
        .button-group {
            max-width: 950px;
            display: flex;
            margin-top: 48px;
            gap: 32px;
            justify-content: space-between;
            flex-direction: row;
            button {
                width: 275px;
                &:hover {
                    transform: translateY(-3px);
                }
            }
        }
    }
    @media (min-width: 1600px) {
    }
`;

const Button = styled.button`
    background: linear-gradient(180deg, #fee444 0%, #b18e32 100%);
    border: none;
    > div {
        display: flex;
    }
    @media ${device.tablet} {
    }
    @media (min-width: 1700px) {
    }
`;

export const LocationForms: React.FC = () => {
    return (
        <Panel>
            <div className="inner-container">
                <StaticImage
                    className="main-image"
                    src="../images/appointments-1.jpg"
                    alt="form"
                    placeholder="none"
                    quality={100}
                />
                <h2>Schedule an Appointment</h2>
                <h3>Please call one of our offices to schedule an appointment:</h3>
                <div className="button-group">
                    <a href="tel:8139336705" rel="noopener noreferrer">
                        <Button>
                            <div>
                                <img src={phoneIcon} alt="phone" />
                                <span>Tampa (Main)</span>
                            </div>
                        </Button>
                    </a>
                    <a href="tel:8133735525" rel="noopener noreferrer">
                        <Button>
                            <div>
                                <img src={phoneIcon} alt="phone" />
                                <span>Riverview (South)</span>
                            </div>
                        </Button>
                    </a>
                    <a href="tel:8135759399" rel="noopener noreferrer">
                        <Button>
                            <div>
                                <img src={phoneIcon} alt="phone" />
                                <span>Land O’ Lakes (North)</span>
                            </div>
                        </Button>{' '}
                    </a>
                </div>
            </div>
        </Panel>
    );
};
