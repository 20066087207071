import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { LocationForms } from '@/LocationForms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Appointments: Page<any> = ({ data }) => {
    return (
        <main>
            <Seo
                title="Appointments – SmileTampa"
                description="An array of informative articles to read about our practice and general health"
            />
            <LocationForms />
        </main>
    );
};

export default Appointments;

Appointments.Layout = DefaultLayout;
